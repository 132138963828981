:root {
  --header: #ec9d5f;
  --navbackground:#f1f1f1;
  --webbackground:white;
  --background2: #6247ea;
  --secondary: #8C644D;
  --light:white;
  --main-text:#838d8a;
  --secondary-text: #E85A4F;
  --contrasting-text: #E98074;
  --footer-color:  #343a40;
  --footer-text: #e85a4f;
  
}

* {
  box-sizing: border-box;
}
body {
  background: var(--webbackground);
  padding: 0px;
  height: 100vh;
  margin: 0px;
  font: 500 100% system-ui, sans-serif;
}

/*  HOLY  GRAIL LAYOUT */

.page-wrap {
  display: inline-block;
  flex-direction: column;
  height: 100vh;
  /* justify-content:space-between; */
  min-height: 100vh;
  box-shadow: 0 0 0px rgba(black, 0.33);
  display:flex;
  flex-direction: column;
  padding: 0rem 0rem 0rem 0rem;
  text-align: LEFT;
  
}
.home_image1 { 
  width: 1600px;
  height: auto;
  margin: 0px;
}
.home_image2{
  display: none;
}
.router-items{
  vertical-align: top;
  height: 100%;
  min-height: max-content;
  vertical-align: top;
  align-self: center;
  align-items: center;
  flex-direction: column;
  background-color:var(--light);
  width:90%;
  text-align:left;
  }
  @media (max-width: 600px) {
    div #text-container {
      width: 100%;
    }
    div #home {
      flex-direction:row;
      width: 100%;
    }
    div .router-items {
      background-color: white;
    }
    
    .home_image1{
      display: none;
    }
     .home_image2{
      display: block;
      width: 100%;
      height: auto;
      margin: 0px;
    }
}

.page-header {
  position:sticky;
  padding: 5px;
  top: 0;
  width: 100%;
  background:var(--header);
  color: white;
  font-weight:normal;
  font-size:SMALL;
  padding-left: 2rem;
}
.page-header div{
  /* display: flex; */
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: var(--primary);
  font-size: larger;
  text-align: left;
}

.page-nav {
  background: var(--navbackground);
}
.page-footer {
  position:fixed;
  left:0;
  bottom:0;
  right:0;
  background: var(--footer-color);
  color: var(--footer-text);
  text-align: center;
  font-size: small;
  padding: 10px;
}


/* END HOLY GRAIL */

/* PAGE CONTAINER CSS */

.pp_input{
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.0;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  margin: 2px 2px  0px 0px;
  appearance: none;
  border-radius: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.pp_input:focus{
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
.pp_title{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: var(--secondary-text);
}
.pp_answer{
  display: block;
  width: 100%;
  height: fit-content;
  font-size: medium;
  border-style: solid;
  border-width: 0px;
  margin: 5px 0px 5px 0px;
}


#navigation{
  color: var(--main-text);

}
div .header-links{
  text-align: left;
  
}
button {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #ec9d5f;
  border-color: #ec9d5f;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  margin: 5px 2px  0px 0px;
  font-size: 14px;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button:hover {
      color: #fff;
      background-color: #fd7e14;
      border-color: #fd7e14;
}
#tip1, #tip2, #tip3, #tip4, #tp_split{
  width: 5rem;
}
table.boostrap4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

table.boostrap4 thead th {
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  border-collapse: collapse;
  box-sizing: border-box;
  text-align: inherit;
  padding: .75rem;
  border-top: 1px solid #75799E;
  vertical-align: bottom;
  border-bottom: 2px solid #75799E;
}

table.boostrap4 td {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  border-collapse: collapse;
  box-sizing: border-box;
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #DDDEEB;
}


/* ----------- Non table related styles */

.table_container {
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
}

body {
  font-family: sans-serif;
}
.pp_container{
  display: flexbox;
  padding:0rem 0rem 0rem 0rem;
}
#text-container{
  padding: 10px;
  /* background-color: rgba(228, 228, 228, 0.5); */
}
#text-container p{
  font-size:medium;
}
