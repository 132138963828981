

.header-split{
    display: flex;
    align-items: center;
    width:100%;
  }
.nav-item{
    flex-grow: 1;
    text-align: left;
    text-transform: uppercase;
    font-size:smaller ;

    border: 0px solid black;    
}
ul {
    column-count: 2;
    list-style: none;
  }
img{
    height:120px ;
    margin-left: 2rem;
}
a:link {
  color: var(--main-text);
  font-weight: bold;
}

/* mouse over link */
a:hover {
  color: Var(--background2);
}

/* selected link */
a:active {
  color: blue;
}
@media (max-width: 600px) {
  ul {
    column-count: 1;
    list-style: none;
  }
}